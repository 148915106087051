import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { defaultLdJson } from '../../static/structuredData';

function SEO({ description, meta, title, image, imgAlt }) {
  const metaDescription = description || '';
  const metaTitle = title ? `Botaretinol - ${title}` : 'Botaretinol';

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Helmet
          htmlAttributes={{
            lang: currentLocale,
          }}
          title={metaTitle}
          titleTemplate={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:image`,
              content: image,
            },
            {
              property: `og:image:alt`,
              content: imgAlt,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:locale`,
              content: currentLocale,
            },
            {
              name: `og:url`,
              content: 'https://www.botaretinol.eu/',
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: 'Brainforge IT',
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `twitter:image`,
              content: image,
            },
            {
              name: `twitter:image:alt`,
              content: imgAlt,
            },
          ].concat(meta)}
        >
          <script type="application/ld+json">
            {JSON.stringify(defaultLdJson)}
          </script>
        </Helmet>
      )}
    </IntlContextConsumer>
  );
}

SEO.defaultProps = {
  meta: [],
  description: '',
  image: '',
  imgAlt: '',
  title: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  imgAlt: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
